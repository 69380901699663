import {mapState, mapActions} from 'vuex'

export const layoutComputed = {
    ...mapState('layout', {
        layoutType: (state) => state.layoutType,
        leftSidebarType: (state) => state.leftSidebarType,
        layoutWidth: (state) => state.layoutWidth,
        topbar: (state) => state.topbar,
        loader: (state) => state.loader
    })
}

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])

export const userMethods = mapActions('user', ['setUser', 'setToken', 'isAdmin'])

export const monitorMethods = mapActions('monitor', ['resetGuests', 'resetPatients', 'resetFeatures', 'resetUsers', 'setPatients', 'setUsers', 'setGuests', 'setMessages', 'setFeatures'])
