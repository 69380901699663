export const state = {
    id: null,
    email: null,
    access_token: null,
    name: '',
    first_name: '',
    last_name: '',
    image: null,
    gender: 1,
    country: null,
    type: null,
    activation_token: ''
}

export const getters = {}

export const mutations = {
    CHANGE_ID(state, id) {
        state.id = id
    }, CHANGE_EMAIL(state, email) {
        state.email = email
    }, CHANGE_IMAGE(state, image) {
        state.image = image
    }, CHANGE_FIRST_NAME(state, name) {
        state.first_name = name
    }, CHANGE_NAME(state, name) {
        state.name = name
    }, CHANGE_LAST_NAME(state, name) {
        state.last_name = name
    }, CHANGE_TOKEN(state, token) {
        state.access_token = token
    }, CHANGE_ACTIVATION_TOKEN(state, token) {
        state.activation_token = token
    }, CHANGE_TYPE(state, type) {
        state.type = type
    }, CHANGE_GENDER(state, gender) {
        state.gender = gender
    }, CHANGE_COUNTRY(state, country) {
        state.country = country
    },
}

export const actions = {
    setUser({commit}, {user}) {
        commit('CHANGE_IMAGE', user.image)
        commit('CHANGE_EMAIL', user.id)
        commit('CHANGE_NAME', (user.first_name + ' ' + user.last_name).trim())
        commit('CHANGE_FIRST_NAME', user.first_name)
        commit('CHANGE_LAST_NAME', user.last_name)
        commit('CHANGE_TYPE', user.type)
        commit('CHANGE_GENDER', user.gender)
        commit('CHANGE_COUNTRY', user.country)
        commit('CHANGE_ACTIVATION_TOKEN', user.activation_token)
    },


    setToken({commit}, {user}) {
        commit('CHANGE_ID', user.user_id)
        commit('CHANGE_TOKEN', user.token)
        commit('CHANGE_TYPE', user.user_type)
    },

    isAdmin() {
        return state.type === 'admin'
    }
}
