export const state = {
  patients: [],
  guests: [],
  messages: [],
  users: [],
  features: {}
}

export const getters = {}

export const mutations = {
  SET_PATIENTS (state, patients) {
    state.patients = patients
  },
  SET_USERS (state, users) {
    state.users = users
  },
  SET_MESSAGES (state, messages) {
    state.messages = messages.map(m => {
      if (!m.image.includes('?unity=true')) m.image += '?unity=true'
      if (m.content && m.content.url && !m.content.url.includes('?unity=true')) m.content.url += '?unity=true'
      return m
    })
  },
  SET_FEATURES (state, features) {
    state.features = features
  },
  SET_GUESTS (state, guests) {
    state.guests = guests
  },
  RESET_PATIENTS (state) {
    state.patients = []
  },
  RESET_USERS (state) {
    state.users = []
  },
  RESET_MESSAGES (state) {
    state.messages = []
  },
  RESET_FEATURES (state) {
    state.features = {}
  },
  RESET_GUESTS (state) {
    state.guests = []
  },
}

export const actions = {
  resetPatients ({ commit }) {
    commit('RESET_PATIENTS')
  },
  resetUsers ({ commit }) {
    commit('RESET_USERS')
  },
  resetMessages ({ commit }) {
    commit('RESET_MESSAGES')
  },
  resetFeatures ({ commit }) {
    commit('RESET_FEATURES')
  },
  resetGuests ({ commit }) {
    commit('RESET_GUESTS')
  },

  setPatients ({ commit }, { patients }) {

    patients = patients.map(p => {
      p.name = p.first_name + ' ' + p.last_name
      if (p.image) {
        p.image += '?unity=true'
      }
      return p
    })
    commit('SET_PATIENTS', patients)
  },

  setUsers ({ commit }, { users }) {
    users = users.map(u => {
      u.name = u.first_name + ' ' + u.last_name
      if (u.image) {
        u.image += '?unity=true'
      }
      return u
    })
    commit('SET_USERS', users)
  },

  setMessages ({ commit }, { messages }) {
    messages = messages.map(m => {
      return m
    })
    commit('SET_MESSAGES', messages)
  },

  setFeatures ({ commit }, { features }) {
    commit('SET_FEATURES', features)
  },

  setGuests ({ commit }, { guests }) {
    commit('SET_GUESTS', guests)
  },
}
