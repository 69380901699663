import store from '@/state/store'

export default [{
    path: '/login', name: 'login', component: () => import('../views/pages/account/login'), meta: {
        beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
                // Redirect to the home page instead
                next({name: 'home'})
            } else {
                // Continue to the login page
                next()
            }
        },
    },
}, {
    path: '/register', name: 'register', component: () => import('../views/pages/account/register'), meta: {
        beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
                // Redirect to the home page instead
                next({name: 'home'})
            } else {
                // Continue to the login page
                next()
            }
        },
    },
}, {
    path: '/forgot-password',
    name: 'Forgot-password',
    component: () => import('../views/pages/account/forgot-password'),
    meta: {
        beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
                // Redirect to the home page instead
                next({name: 'home'})
            } else {
                // Continue to the login page
                next()
            }
        },
    },
}, {
    path: '/logout', name: 'logout', beforeEnter: (routeTo, routeFrom, next) => {
        sessionStorage.removeItem('accessToken')
        sessionStorage.removeItem('userId')
        next({name: 'login'})
    },
}, {
    path: '/', name: 'home', meta: {
        authRequired: true,
    }, component: () => import('../views/pages/overview/index')
}, {
    path: '/pedisteps', name: 'Pedisteps', meta: {
        authRequired: true,
    }, component: () => import('../views/pages/pedisteps/index')
}, {
    path: '/chat', name: 'Chat', meta: {
        authRequired: true,
    }, component: () => import('../views/pages/chat/index')
}, {
    path: '/calendar', name: 'Calendar', meta: {
        authRequired: true,
    }, component: () => import('../views/pages/calendar/index')
}, {
    path: '/admin/map',
    name: 'User Map',
    meta: {authRequired: true},
    component: () => import('../views/pages/admin/map')
}, {
    path: '/account/create',
    name: 'Account Create',
    meta: {authRequired: true},
    component: () => import('../views/pages/account/create')
}, {
    path: '/patients-board',
    name: 'Patients-board',
    meta: {authRequired: true},
    component: () => import('../views/pages/patientsboard/index')
},/*
{
    path: '/email/inbox',
    name: 'Inbox',
    meta: {authRequired: true},
    component: () => import('../views/pages/email/inbox')
},
{
    path: '/email/read',
    name: 'Read Email',
    meta: {authRequired: true},
    component: () => import('../views/pages/email/reademail')
},{ {
  path: '/pages/starter',
  name: 'Starter Page',
  meta: { authRequired: true },
  component: () => import('../views/pages/ui/utility/starter')
}, {
  path: '/pages/maintenance',
  name: 'Maintenance',
  meta: { authRequired: true },
  component: () => import('../views/pages/ui/utility/maintenance')
}, {
  path: '/pages/coming-soon',
  name: 'Coming-soon',
  meta: { authRequired: true },
  component: () => import('../views/pages/ui/utility/coming-soon')
}, {
  path: '/pages/timeline',
  name: 'Timeline',
  meta: { authRequired: true },
  component: () => import('../views/pages/ui/utility/timeline/index')
}, {
  path: '/pages/faqs',
  name: 'Faqs',
  meta: { authRequired: true },
  component: () => import('../views/pages/ui/utility/faqs')
}, {
  path: '/pages/pricing',
  name: 'Pricing',
  meta: { authRequired: true },
  component: () => import('../views/pages/ui/utility/pricing/index')
}, {
  path: '/pages/error-404',
  name: 'Error-404',
  meta: { authRequired: true },
  component: () => import('../views/pages/ui/utility/error-404')
}, {
  path: '/pages/error-500',
  name: 'Error-500',
  meta: { authRequired: true },
  component: () => import('../views/pages/ui/utility/error-500')
}, {
  path: '/form/elements',
  name: 'Form Elements',
  meta: { authRequired: true },
  component: () => import('../views/pages/forms/elements')
}, {
  path: '/form/validation',
  name: 'Form validation',
  meta: { authRequired: true },
  component: () => import('../views/pages/forms/validation')
}, {
  path: '/form/advanced',
  name: 'Form Advanced',
  meta: { authRequired: true },
  component: () => import('../views/pages/forms/advanced')
}, {
  path: '/form/editor',
  name: 'CK Editor',
  meta: { authRequired: true },
  component: () => import('../views/pages/forms/ckeditor')
}, {
  path: '/form/uploads',
  name: 'File Uploads',
  meta: { authRequired: true },
  component: () => import('../views/pages/forms/uploads')
}, {
  path: '/form/wizard',
  name: 'Form Wizard',
  meta: { authRequired: true },
  component: () => import('../views/pages/forms/wizard')
}, {
  path: '/form/mask',
  name: 'Form Mask',
  meta: { authRequired: true },
  component: () => import('../views/pages/forms/mask')
}, {
  path: '/tables/basic',
  name: 'Basic Tables',
  meta: { authRequired: true },
  component: () => import('../views/pages/ui/tables/basictable')
}, {
  path: '/tables/advanced',
  name: 'Advanced Tables',
  meta: { authRequired: true },
  component: () => import('../views/pages/ui/tables/advancedtable')
}, {
  path: '/charts/apex',
  name: 'Apex chart',
  meta: { authRequired: true },
  component: () => import('../views/pages/charts/apex')
}, {
  path: '/charts/chartjs',
  name: 'Chartjs chart',
  meta: { authRequired: true },
  component: () => import('../views/pages/charts/chartjs/index')
}, {
  path: '/charts/chartist',
  name: 'Chartist chart',
  meta: { authRequired: true },
  component: () => import('../views/pages/charts/chartist')
}, {
  path: '/charts/echart',
  name: 'Echart chart',
  meta: { authRequired: true },
  component: () => import('../views/pages/charts/echart/index')
},
  path: '/maps/google',
  name: 'Google Maps',
  meta: { authRequired: true },
  component: () => import('../views/pages/maps/google')
}, {
  path: '/maps/leaflet',
  name: 'Leaflet Maps',
  meta: { authRequired: true },
  component: () => import('../views/pages/maps/leaflet/index')
}, {
  path: '/icons',
  name: 'Icons',
  meta: { authRequired: true },
  component: () => import('../views/pages/icons/dripicons')
},*/]
