class Api {
    constructor(config) {
        this.apiUrl = () => {
            return config.url
        }
    }

    /**
     * Registers the user with given details
     */
    /*
    registerUser = (email, password) => {
    }
  */
    /**
     * Login user with given details
     */
    loginUser = (email, password, uuid) => {
        const url = new URL(this.apiUrl())
        url.search = new URLSearchParams({
            username: email, password, uuid
        })
        return fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Action': 'login'
            },
        })
    }

    /**
     * Get user with given details
     */
    getUser = (userId, token, uuid, allMessages = true) => {
        const url = new URL(this.apiUrl())
        url.search = new URLSearchParams({
            user_id: userId, all_messages: allMessages, uuid
        })
        return fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Action': 'user', 'Token': token
            },
        })
    }

    getPatients = (userId, token) => {
        const url = new URL(this.apiUrl())
        url.search = new URLSearchParams({
            user_id: userId,
        })
        return fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Action': 'patients', 'Token': token
            },
        })
    }

    getUsers = (userId, token) => {
        console.log("getUsers", userId, token);
        const url = new URL(this.apiUrl())
        url.search = new URLSearchParams({
            user_id: userId,
        })
        return fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Action': 'users', 'Token': token
            },
        })
    }

    getExercises = (userId, token, patientId) => {
        const url = new URL(this.apiUrl())
        url.search = new URLSearchParams({
            user_id: userId, patient_id: patientId
        })
        return fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Action': 'getExercises', 'Token': token
            },
        })
    }

    getMessages = (userId, token) => {
        const url = new URL(this.apiUrl())
        url.search = new URLSearchParams({
            user_id: userId,
        })
        return fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Action': 'messages', 'Token': token
            },
        })
    }

    getPatientLog = (userId, token, patientId = 0, dateFrom = '', dateTo = '') => {
        const url = new URL(this.apiUrl())
        url.search = new URLSearchParams({
            user_id: userId, patient_id: patientId, date_from: dateFrom, date_to: dateTo
        })
        return fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Action': 'patientLog', 'Token': token
            },
        })
    }

    getGuests = (userId, token) => {
        const url = new URL(this.apiUrl())
        url.search = new URLSearchParams({
            user_id: userId,
        })
        return fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Action': 'patients', 'Token': token
            },
        })
    }

    /**
     * forget Password user with given details
     */
    /*
    forgetPassword = (email) => {

    }
  */

    createAccount = async (userId, token, account) => {
        return fetch(this.apiUrl(), {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json', 'Action': 'createUser', 'Token': token
            }, body: JSON.stringify({
                user_id: userId, account
            })
        })
    }
    /**
     * Logout the user
     */
    logout = () => {
    }

    setLoggedInUser = (user) => {
        sessionStorage.setItem('authUser', JSON.stringify(user))
    }

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        if (!sessionStorage.getItem('authUser')) {
            return null
        }
        return JSON.parse(sessionStorage.getItem('authUser'))
    }

    /**
     * Handle the error
     * @param {*} error
     */
    _handleError(error) {
        let errorMessage = error.message
        return errorMessage
    }
}

let _api = null

/**
 * Initialize the api
 * @param {*} config
 */
const initApi = (config) => {
    if (!_api) {
        _api = new Api(config)
    }
    return _api
}

/**
 * Returns the Api
 */
const getApi = () => {
    if (!_api) {
        throw new Error("API not initialized. Please call initApi(config) before using getApi.");
    }
    return _api;
}

export {initApi, getApi}
